import Http from "@/services/api.service";
import { service_url } from "@/services/config";

export function loadList(data: string) {
    return Http._axios({
        url: `${service_url}/api/v1/contacts/`,
        method: "post",
        data
    });
}
export function loadListElastic(data: string) {
    return Http._axios({
        url: `${service_url}/api/v2/contacts/`,
        method: "post",
        data
    });
}
export function loadRules() {
    return Http._axios({
        url: `${service_url}/api/v1/rules/`,
        method: "get"
    });
}
export function loadElasticRules() {
    return Http._axios({
        url: `${service_url}/api/v2/rules/`,
        method: "get"
    });
}
export function loadDetails(id: string) {
    return Http._axios({
        url: `${service_url}/api/v1/contacts/${id}/`,
        method: "get"
    });
}
export function loadTemplates(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v1/filters/?${filter || ''}`,
        method: "get"
    });
}
export function loadTemplates_v2(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v2/filters/?${filter || ''}`,
        method: "get"
    });
}
export function createTemplate(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/filters/create/`,
        method: "post",
        data
    });
}

export function updateTemplate(data: any, id: string) {
    return Http._axios({
        url: `${service_url}/api/v1/filters/${id}/update/`,
        method: "patch",
        data
    });
}


export function importCSV(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/contacts/import/`,
        method: "post",
        headers: {
            "Content-Disposition": `attachment; filename=${typeof data === "string" ? data : data.name
                }`
        },
        data: data
    });
}
export function importCSV_v2(data: any, fileName: any) {
    return Http._axios({
        url: `${service_url}/api/v2/contacts/import/`,
        method: "post",
        headers: {
            "Content-Disposition": `attachment; filename=${typeof data === "string" ? fileName : fileName
                }`
        },
        data: data
    });
}

export function getFilesLogs(filter: any) {
    return Http._axios({
        url: `${service_url}/api/v2/csv-tasks/?${filter || ''}`,
        method: "get"
    });
}


export function createTemplate_v2(data: any) {
    return Http._axios({
        url: `${service_url}/api/v2/filters/create/`,
        method: "post",
        data
    });
}

export function updateTemplate_v2(data: any) {
    return Http._axios({
        url: `${service_url}/api/v2/filters/${data.id}/update/`,
        method: "patch",
        data: data.data
    });
}

export function loadElasticPathes() {
    return Http._axios({
        url: `${service_url}/api/v2/rules/paths/`,
        method: "get"
    });
}

export function loadNewContacts(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v2/contacts/stats/?${filter || ''}`,
        method: "get"
    });
}